<template>
  <g :id="`Male56AvatarMobile_${uniqueIdSuffixA}`">
    <circle style="fill: #d0d0d0" cx="68.5" cy="135.3" r="63.6" />
    <g>
      <path
        :style="{ fill: ringColorHex }"
        :stroke="ringColorHex ? 'rgba(255,255,255,0.5)' : ''"
        d="M68.5,198.9c-35.1,0-63.6-28.5-63.6-63.6s28.5-63.6,63.6-63.6c35.1,0,63.6,28.5,63.6,63.6
				S103.6,198.9,68.5,198.9z M68.5,82.2c-29.3,0-53.1,23.8-53.1,53.1s23.8,53.1,53.1,53.1s53.1-23.8,53.1-53.1S97.8,82.2,68.5,82.2z
				"
      />
    </g>
    <g>
      <path
        style="fill: none"
        d="M33.1,169.4l7.2-2l10.3-2.8l5.1-7.6l0.6,0.5v-8.3l0.2-0.2l0,0c-4-2.9-6.5-7.4-6.5-13v-1.4h-0.1
			c0,0-1.8-0.9-2.4-1.3c-1.4-0.8-2.2-1.6-2.2-3.5v-3.7c0-1.9,0.3-3.5,2.2-3.5h2.4l0.1-0.7v-7.6c0-0.5,0-0.9,0.1-1.4l0,0
			c0.7-9.2,8.4-16.5,17.9-16.5h3c9.5,0,17.3,7.4,17.9,16.7c0,0.4,0,0.8,0,1.2l0.3,7.3v0.4l0.2,0.5h2.1c1.9,0,2.2,1.6,2.2,3.5v3.7
			c0,1.9-0.8,2.6-2.2,3.5c-0.6,0.3-2.4,1.3-2.4,1.3h-0.2v1.4c0,5.4-2.4,10-6.3,12.9l0,0c0,0-0.1,0-0.1,0.1l0,0l0.2,0.2v8.3l0.6-0.5
			l5.1,7.6l10.3,2.8l7.2,2c0.3,0.1,0.7,0.2,1,0.3c8.2-9.1,13.3-21.2,13.3-34.4c0-28.5-23.1-51.6-51.6-51.6S17,106.7,17,135.2
			c0,13.5,5.3,25.8,13.8,35C31.5,170,32.3,169.7,33.1,169.4z"
      />
      <path
        style="fill: #333333"
        d="M50.6,164.6l-10.3,2.8l-7.2,2c-0.8,0.2-1.6,0.5-2.4,0.9c6.8,7.3,15.7,12.7,25.8,15.1l-6.1-20.5
			L50.6,164.6z"
      />
      <path
        style="fill: #333333"
        d="M98.6,167.5l-10.3-2.8l0.2,0.3l-0.3,0.4L82.4,185c9.6-2.7,18-8,24.4-15.2c-0.3-0.1-0.7-0.2-1-0.3
			L98.6,167.5z"
      />
      <path
        style="fill: #edc593"
        d="M58.7,142.2c0.3-1.1,4.5-4.1,7-5.3c0.9-0.4,1.9,0,2.2,1c0.1,0.3,0.1,0.4-0.1,0.5
			c-0.7,0.2-6.1,3.9-6.2,5.1c-0.1,1.2,3.2,8.4,4.6,8.4c1,0,1.2-1.8,1.3-2.9c0-0.6,0.4-1,0.9-1.1H70c0.5,0.1,0.9,0.5,0.9,1.1
			c0.1,1.1,0.3,2.9,1.3,2.9c1.4,0,4.7-7.2,4.6-8.4s-5.5-4.9-6.2-5.1c-0.2-0.1-0.2-0.3-0.1-0.5c0.3-1,1.4-1.4,2.2-1
			c2.5,1.2,6.7,4.2,7,5.3c0.6,2.4-1.3,6.9-2.8,9.7c2.1-0.6,4.1-1.6,5.7-2.9c0,0,0.1,0,0.1-0.1l0,0c3.8-2.9,6.3-7.5,6.3-12.9v-1.4
			h0.2c0,0,1.8-0.9,2.4-1.3c1.4-0.8,2.2-1.6,2.2-3.5v-3.7c0-1.9-0.3-3.5-2.2-3.5h-2.1l-0.2-0.5l-0.5,6.1l-0.6-1.5
			c0,0,0.2-6.2-1.1-12.3c-1.2-5.6-3.7-11.1-8.9-11.4c-3-0.2-4.3,0.9-7.9,0.8s-6-0.8-8.6-0.8c-5.3,0.1-7.8,5.8-9,11.6
			c-1.2,6-1.1,12.1-1.1,12.1l-0.6,1.5l-0.9,6.3v1.4c0,5.5,2.5,10.1,6.5,13l0,0c1.4,1.1,3.1,1.9,4.8,2.5
			C59.8,148.7,58.1,144.5,58.7,142.2z M67.8,129.4c0.3,0,1.3,0,1.9-0.5c0.4-0.4,0.6-0.9,0.5-1.8c0-0.7,0.5-1.3,1.2-1.3
			s1.3,0.5,1.3,1.2c0.1,2-0.7,3.1-1.3,3.7c-1.1,1-2.6,1.2-3.3,1.2c-0.3,0-0.5,0-0.5,0c-0.7-0.1-1.2-0.7-1.1-1.4
			C66.5,129.8,67.1,129.4,67.8,129.4z"
      />
      <path
        style="fill: #ffffff"
        d="M87.9,126.9l0.6,1.5l0.5-6.1v-0.4l-0.3-7.3c0-0.4,0-0.8,0-1.2l-2,1.3
			C88.1,120.7,87.9,126.9,87.9,126.9z"
      />
      <path
        style="fill: #575756"
        d="M52.5,114.8c1.2-5.8,3.7-11.5,9-11.6c2.5,0,4.9,0.7,8.6,0.8c3.5,0.1,4.8-1,7.9-0.8
			c5.1,0.3,7.7,5.8,8.9,11.4l2-1.3C88.3,104,80.5,96.6,71,96.6h-3c-9.4,0-17.2,7.3-17.9,16.5l0,0L52.5,114.8L52.5,114.8z"
      />
      <path
        style="fill: #f5f5f5"
        d="M74.7,173.8l5,4.5l8.5-13l0.3-0.4l-0.2-0.3l-1-0.3c-2.3-0.6-4.6-2.7-4.6-5v-1.8l-12.8,11.9
			L74.7,173.8z"
      />
      <path
        style="fill: #f5f5f5"
        d="M87.2,164.4l1,0.3l-5.1-7.6l-0.6,0.5v1.8C82.6,161.7,85,163.8,87.2,164.4z"
      />
      <path
        style="fill: #ececec"
        d="M79.7,178.3l-5-4.5L73,179h-0.4l1,7.6c3-0.3,5.9-0.9,8.8-1.7l5.8-19.6L79.7,178.3z"
      />
      <path
        style="fill: #d3ae84"
        d="M69.8,169.4l12.8-11.9v-8.3l-0.2-0.2l0,0c-1.7,1.3-3.6,2.3-5.7,2.9c-0.5,1.1-1,1.9-1.3,2.3
			c-1.1,1.5-6.3,1.3-6.3,1.3s-5.2,0.2-6.3-1.3c-0.3-0.5-0.9-1.5-1.5-2.7c-1.8-0.6-3.4-1.4-4.8-2.5l-0.2,0.2v8.3l12.8,11.9H69.8z"
      />
      <path
        style="fill: #4080c1"
        d="M66,179l-1,7.7c1.1,0.1,2.3,0.2,3.4,0.2c1.7,0,3.4-0.1,5.1-0.3l-1-7.6L66,179L66,179z"
      />
      <polygon
        style="fill: #3b79af"
        points="69.1,169.4 64.3,173.7 66,179 72.6,179 73,179 74.7,173.8 69.8,169.4 		"
      />
      <path
        style="fill: #d3ae84"
        d="M67.5,131.9c0.1,0,0.3,0,0.5,0c0.8,0,2.2-0.2,3.3-1.2c0.6-0.6,1.4-1.7,1.3-3.7
			c0-0.7-0.6-1.2-1.3-1.2c-0.7,0-1.2,0.6-1.2,1.3c0,0.8-0.1,1.4-0.5,1.8c-0.6,0.5-1.6,0.6-1.9,0.5c-0.7-0.1-1.3,0.4-1.4,1.1
			C66.3,131.2,66.8,131.8,67.5,131.9z"
      />
      <path
        style="fill: #f5f5f5"
        d="M59.2,178.3l5.1-4.6l4.8-4.3l-12.8-11.9v1.8c0,2.3-2.3,4.4-4.6,5l-1,0.3l-0.2,0.3L59.2,178.3z"
      />
      <path
        style="fill: #ececec"
        d="M64.3,173.7l-5.1,4.6l-8.8-13.4l6.1,20.5c2.8,0.7,5.6,1.1,8.6,1.3l1-7.7L64.3,173.7z"
      />
      <path
        style="fill: #f5f5f5"
        d="M56.3,159.4v-1.8l-0.6-0.5l-5.1,7.6l1-0.3C53.9,163.8,56.3,161.7,56.3,159.4z"
      />
      <path
        style="fill: #ffffff"
        d="M51.4,126.9c0,0-0.2-6.1,1.1-12.1l0,0l-2.5-1.6c0,0.5-0.1,0.9-0.1,1.4v7.6l-0.1,0.7l1,5.6
			L51.4,126.9z"
      />
      <path
        style="fill: #edc593"
        d="M49.8,122.8h-2.4c-1.9,0-2.2,1.6-2.2,3.5v3.7c0,1.9,0.8,2.6,2.2,3.5c0.6,0.3,2.4,1.3,2.4,1.3h0.1
			l0.9-6.3L49.8,122.8z"
      />
      <path
        style="fill: #575756"
        d="M79.5,142.2c-0.3-1.1-4.5-4.1-7-5.3c-0.9-0.4-1.9,0-2.2,1c-0.1,0.3-0.1,0.4,0.1,0.5
			c0.7,0.2,6.1,3.9,6.2,5.1c0.1,1.2-3.2,8.4-4.6,8.4c-1,0-1.2-1.8-1.3-2.9c0-0.6-0.4-1-0.9-1.1h-1.6c-0.5,0.1-0.9,0.5-0.9,1.1
			c-0.1,1.1-0.3,2.9-1.3,2.9c-1.4,0-4.7-7.2-4.6-8.4c0.1-1.2,5.5-4.9,6.2-5.1c0.2-0.1,0.2-0.3,0.1-0.5c-0.3-1-1.4-1.4-2.2-1
			c-2.5,1.2-6.7,4.2-7,5.3c-0.6,2.3,1.1,6.5,2.6,9.3c0.9,0.3,1.8,0.6,2.8,0.8h11.4c0.4-0.1,0.8-0.2,1.2-0.4
			C78.2,149.1,80,144.7,79.5,142.2z"
      />
      <path
        style="fill: #575756"
        d="M64.1,152.3c-1-0.2-1.9-0.5-2.8-0.8c0.6,1.3,1.2,2.3,1.5,2.7c1.1,1.5,6.3,1.3,6.3,1.3
			s5.2,0.2,6.3-1.3c0.3-0.4,0.8-1.3,1.3-2.3c-0.4,0.1-0.8,0.3-1.2,0.4L64.1,152.3L64.1,152.3z"
      />
    </g>
  </g>
</template>
<script>
export default {
  props: {
    ringColor: {
      type: String,
      default: "#b5b4b5",
    },
  },
  computed: {
    ringColorHex() {
      return this.ringColor;
    },
    uniqueIdSuffixA() {
      return Math.floor(Math.random() * (25 - 2) + 2);
    },
    uniqueIdSuffixB() {
      return Math.floor(Math.random() * (50 - 26) + 62);
    },
  },
};
</script>
